/* src/components/Navbar.css */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #333;
    padding: 10px 20px;
    color: white;
  }
  
  .navbar-logo img {
    height: 70px;
    border-radius: 10px
  }
  
  .navbar-links {
    list-style-type: none;
    display: flex;
    margin: 0;
    padding: 0;
  }
  
  .navbar-links li {
    margin-left: 20px;
  }
  
  .navbar-links a {
    color: white;
    text-decoration: none;
    font-size: 16px;
  }
  
  .navbar-links a:hover {
    text-decoration: underline;
  }
  