.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*custom font*/
@import url(https://fonts.googleapis.com/css?family=Montserrat);

/*basic reset*/
* {margin: 0; padding: 0;}

html {
  height: 100%;
  
  background: 
    linear-gradient(rgba(196, 102, 0, 0.6), rgba(155, 89, 182, 0.6));
}

body {
  font-family: montserrat, arial, verdana;
}
/*form styles*/
#msform {
  width: 400px;
  margin: 50px auto;
  text-align: center;
  position: relative;
}
#msform fieldset {
  background: white;
  border: 0 none;
  border-radius: 3px;
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.4);
  padding: 20px 30px;
  box-sizing: border-box;
  width: 80%;
  margin: 0 10%;
  
  /*stacking fieldsets above each other*/
  position: relative;
}
/*Hide all except first fieldset*/
#msform fieldset:not(:first-of-type) {
  display: none;
}
/*inputs*/
#msform input, #msform textarea {
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-bottom: 10px;
  width: 100%;
  box-sizing: border-box;
  font-family: montserrat;
  color: #2C3E50;
  font-size: 13px;
}
/*buttons*/
#msform .action-button {
  width: 100px;
  background: #27AE60;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 1px;
  cursor: pointer;
  padding: 10px;
  margin: 10px 5px;
  text-decoration: none;
  font-size: 14px;
}
#msform .action-button:hover, #msform .action-button:focus {
  box-shadow: 0 0 0 2px white, 0 0 0 3px #27AE60;
}
/*headings*/
.fs-title {
  font-size: 15px;
  text-transform: uppercase;
  color: #2C3E50;
  margin-bottom: 10px;
}
.fs-subtitle {
  font-weight: normal;
  font-size: 13px;
  color: #666;
  margin-bottom: 20px;
}

/* src/App.css */
.App {
  text-align: center;
}

/* src/App.css */
.App {
  text-align: center;
}

.listing {
  border: 1px solid #000000;
  padding: 10px;
  margin: 10px;
  display: inline-block;
  width: 200px;
  vertical-align: top;
  transition: transform 0.3s ease-in-out;
  background: linear-gradient(135deg, #000000, #555555); /* Black to grey gradient */
  color: white; /* Ensure text is readable on dark background */
  border-radius: 10px; /* Optional: rounded corners for a more modern look */
}

.listings {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.listing img {
  margin-bottom: 10px;
  width: 150px;
  height: 150px;
}

.listing:hover {
  transform: scale(1.1); /* Increase size by 10% on hover */
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2); /* Optional: add a shadow for more emphasis */
}

#price {
  color: red;
  font-weight: bolder;
}

.amazon-button {
  background-color: #ff9900;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin-top: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  border-radius: 5px; /* Rounded corners */
}

.amazon-button:hover {
  background-color: #e68900; /* Darker orange on hover */
}